import { gql } from "graphql-request";
import { queryGraphQL } from "./backend";
import { Language } from "./languages";
import { User } from "./users";

export interface GifInput {
    provider: string;
    providerId: string;
    embedUrl: string;
  }

  export interface Gif {
    id: number;
    provider: string;
    providerId: string;
    embedUrl: string;
  }
  
  export const insertGif = async (
    user: User,
    input: GifInput
  ) => {
    const query = gql`
      mutation InsertGif($sessionId: int!, $sessionToken: String!, $input: GifInput!) {
        insertGif(sessionId: $sessionId, sessionToken: $sessionToken, input: $input)
      }
    `;
    return await queryGraphQL<number>(
      query,
      {
        sessionId:user.id,
        sessionToken:user.token,
        input,
      },
      "insertGif"
    );
  };

  export const getGif = async (
    user: User,
    gifId: number,
  ) => {
    const query = gql`
      query GetGif($sessionId: Int!, $sessionToken: String!, $gifId: Int!) {
        getGif(sessionId: $sessionId, sessionToken: $sessionToken, gifId: $gifId) {
          id
          provider
          providerId
          embedUrl
        }
      }
    `;
    return await queryGraphQL<Gif>(
      query,
      { sessionId:user.id, sessionToken:user.token, gifId },
      "getGif"
    );
  };

export interface GifSuggestion {
    keywords: string;
    provider: string;
    providerId: string;
    embedUrl: string;
  }

export const suggestBestGif = async (
    user: User,
    topics: string,
    title: string,
    description: string,
    language: Language,
  ) => {
    const graphqlQuery = gql`
      query SuggestBestGif(
        $sessionId: Int!
        $sessionToken: String!
        $topics: String!
        $title: String!
        $description: String!
        $language: Language!
      ) {
        suggestBestGif(
          sessionId: $sessionId
          sessionToken: $sessionToken
          topics: $topics
          title: $title
          description: $description
          language: $language
        ) {
            keywords
            provider
            providerId
            embedUrl
        }
      } 
    `;
    return await queryGraphQL<GifSuggestion | undefined>(
      graphqlQuery,
      { sessionId:user.id, sessionToken:user.token, topics, title, description, language },
      "suggestBestGif"
    );
  };