import { MenuItem } from "@mui/material";
import { LanguageLabels, Language, LanguageKeys } from "../model/languages";
import { SelectMenu } from "./selectmenu";

export const LanguageMenu = ({
  language,
  fullWidth,
  onChange,
}: {
  language: Language;
  fullWidth?: boolean;
  onChange: (language: Language) => void;
}) => {
  return (
    <SelectMenu
      fullWidth={fullWidth}
      id="lang"
      value={language.toLocaleLowerCase()}
      label="Language"
      onChange={(value) => onChange(value.toUpperCase() as Language)}
    >
      {Object.keys(LanguageLabels).map((lang) => (
        <MenuItem key={lang} value={lang}>
          {LanguageLabels[lang as LanguageKeys]}
        </MenuItem>
      ))}
    </SelectMenu>
  );
};
