import { gql } from "graphql-request";
import { queryGraphQL } from "./backend";
import { User } from "./users";
import { SUB_COLORS } from "../theme";
import { Language, Languages } from "./languages";

export type InterestColors = keyof typeof SUB_COLORS;

export interface Interest {
  id: number;
  depth: number;
  names: Languages;
  color: string;
  parentId: number | undefined;
  active: boolean;
  users: number;
}

export const getInterest = async (user: User, id: number) => {
  const query = gql`
    query GetBoInterest($sessionId: Int!, $sessionToken: String!, $id: Int!) {
      getBoInterest(sessionId: $sessionId, sessionToken: $sessionToken, id: $id) {
        id
        depth
        color
        parentId
        names {
          en
          es
          fr
          ukr
        }
        active
      }
    }
  `;
  return await queryGraphQL<Interest[]>(
    query,
    { sessionId:user.id, sessionToken: user.token, id },
    "getBoInterest"
  );
};

export const getSubInterest = async (user: User, id: number) => {
  const query = gql`
    query GetBoSubInterest($sessionId: Int!, $sessionToken: String!, $id: Int!) {
      getBoSubInterest(sessionId: $sessionId, sessionToken: $sessionToken, id: $id) {
        id
        depth
        color
        parentId
        names {
          en
          es
          fr
          ukr
        }
        active
      }
    }
  `;
  return await queryGraphQL<Interest[]>(
    query,
    { sessionId:user.id, sessionToken: user.token, id },
    "getBoSubInterest"
  );
};

export const getSubSubInterest = async (user: User, id: number) => {
  const query = gql`
    query GetBoSubSubInterest($sessionId: Int!, $sessionToken: String!, $id: Int!) {
      getBoSubSubInterest(sessionId: $sessionId, sessionToken: $sessionToken, id: $id) {
        id
        depth
        color
        parentId
        names {
          en
          es
          fr
          ukr
        }
        active
      }
    }
  `;
  return await queryGraphQL<Interest[]>(
    query,
    { sessionId:user.id, sessionToken: user.token, id },
    "getBoSubSubInterest"
  );
};

export const getInterestUsers = async (
  user: User,
  interestId: number,
  from: number,
  size: number
) => {
  const query = gql`
    query GetInterestUsers(
      $sessionId: Int!
      $sessionToken: String!
      $interestId: Int!
      $from: Int!
      $size: Int!
    ) {
      getInterestUsers(
        sessionId: $sessionId
        sessionToken: $sessionToken
        interestId: $interestId
        from: $from
        size: $size
      ) {
        name
        email
      }
    }
  `;
  return await queryGraphQL<Array<User>>(
    query,
    { sessionId:user.id, sessionToken: user.token, interestId, from, size },
    "getInterestUsers"
  );
};

export const getInterests = async (
  user: User,
  includeInactive: boolean
) => {
  const query = gql`
    query GetBoInterests(
      $sessionId: Int!
      $sessionToken: String!
      $includeInactive: Boolean!
    ) {
      getBoInterests(
        sessionId: $sessionId
        sessionToken: $sessionToken
        includeInactive: $includeInactive
      ) {
        id
        depth
        color
        parentId
        names {
          en
          es
          fr
          ukr
        }
        active
        users
      }
    }
  `;
  return await queryGraphQL<Array<Interest>>(
    query,
    { sessionId: user.id, sessionToken: user.token, includeInactive },
    "getBoInterests"
  );
};

export const getSubInterests = async (
  user: User,
  parentId: number,
  includeInactive: boolean
) => {
  const query = gql`
    query GetBoSubInterests(
      $sessionId: Int!
      $sessionToken: String!
      $parentId: Int!
      $includeInactive: Boolean!
    ) {
      getBoSubInterests(
        sessionId: $sessionId
        sessionToken: $sessionToken
        parentId: $parentId
        includeInactive: $includeInactive
      ) {
        id
        depth
        color
        parentId
        names {
          en
          es
          fr
          ukr
        }
        active
        users
      }
    }
  `;
  return await queryGraphQL<Array<Interest>>(
    query,
    { sessionId: user.id, sessionToken: user.token, parentId, includeInactive },
    "getBoSubInterests"
  );
};

export const getSubSubInterests = async (
  user: User,
  parentId: number,
  includeInactive: boolean
) => {
  const query = gql`
    query GetBoSubSubInterests(
      $sessionId: Int!
      $sessionToken: String!
      $parentId: Int!
      $includeInactive: Boolean!
    ) {
      getBoSubSubInterests(
        sessionId: $sessionId
        sessionToken: $sessionToken
        parentId: $parentId
        includeInactive: $includeInactive
      ) {
        id
        depth
        color
        parentId
        names {
          en
          es
          fr
          ukr
        }
        active
        users
      }
    }
  `;
  return await queryGraphQL<Array<Interest>>(
    query,
    { sessionId: user.id, sessionToken: user.token, parentId, includeInactive },
    "getBoSubSubInterests"
  );
};

export const findInterests = async (
  user: User,
  query: string,
  language: Language,
  includeInactive: boolean
) => {
  const graphqlQuery = gql`
    query FindBoInterests(
      $sessionId: Int!
      $sessionToken: String!
      $query: String!
      $language: Language!
      $includeInactive: Boolean!
    ) {
      findBoInterests(
        sessionId: $sessionId
        sessionToken: $sessionToken
        query: $query
        language: $language
        includeInactive: $includeInactive
      ) {
        id
        depth
        color
        parentId
        names {
          en
          es
          fr
          ukr
        }
        active
        users
      }
    }
  `;
  return await queryGraphQL<Array<Interest>>(
    graphqlQuery,
    { sessionId: user.id, sessionToken: user.token, query, language, includeInactive },
    "findBoInterests"
  );
};

export const findSubInterests = async (
  user: User,
  query: string,
  language: Language,
  includeInactive: boolean
) => {
  const graphqlQuery = gql`
    query FindBoSubInterests(
      $sessionId: Int!
      $sessionToken: String!
      $query: String!
      $language: Language!
      $includeInactive: Boolean!
    ) {
      findBoSubInterests(
        sessionId: $sessionId
        sessionToken: $sessionToken
        query: $query
        language: $language
        includeInactive: $includeInactive
      ) {
        id
        depth
        color
        parentId
        names {
          en
          es
          fr
          ukr
        }
        active
        users
      }
    }
  `;
  return await queryGraphQL<Array<Interest>>(
    graphqlQuery,
    { sessionId: user.id, sessionToken: user.token, query, language, includeInactive },
    "findBoSubInterests"
  );
};

export const findSubSubInterests = async (
  user: User,
  query: string,
  language: Language,
  includeInactive: boolean
) => {
  const graphqlQuery = gql`
    query FindBoSubSubInterests(
      $sessionId: Int!
      $sessionToken: String!
      $query: String!
      $language: Language!
      $includeInactive: Boolean!
    ) {
      findBoSubSubInterests(
        sessionId: $sessionId
        sessionToken: $sessionToken
        query: $query
        language: $language
        includeInactive: $includeInactive
      ) {
        id
        depth
        color
        parentId
        names {
          en
          es
          fr
          ukr
        }
        active
        users
      }
    }
  `;
  return await queryGraphQL<Array<Interest>>(
    graphqlQuery,
    { sessionId: user.id, sessionToken: user.token, query, language, includeInactive },
    "findBoSubSubInterests"
  );
};

export const createInterest = async (
  user: User,
  color: string,
  names: Languages,
  active: boolean
) => {
  const query = gql`
    mutation CreateInterest(
      $sessionId: Int!
      $sessionToken: String!
      $color: String!
      $names: LanguagesInput!
      $active: Boolean!
    ) {
      createInterest(
        sessionId: $sessionId
        sessionToken: $sessionToken
        color: $color
        names: $names
        active: $active
      )
    }
  `;
  return await queryGraphQL<boolean>(
    query,
    { sessionId: user.id, sessionToken: user.token, color, names, active },
    "createInterest"
  );
};

export const createSubInterest = async (
  user: User,
  parent: number,
  names: Languages,
  active: boolean
) => {
  const query = gql`
    mutation CreateSubInterest(
      $sessionId: Int!
      $sessionToken: String!
      $parent: Int!
      $names: LanguagesInput!
      $active: Boolean!
    ) {
      createSubInterest(
        sessionId: $sessionId
        sessionToken: $sessionToken
        parent: $parent
        names: $names
        active: $active
      )
    }
  `;
  return await queryGraphQL<boolean>(
    query,
    { sessionId: user.id, sessionToken: user.token, parent, names, active },
    "createSubInterest"
  );
};

export const createSubSubInterest = async (
  user: User,
  parent: number,
  names: Languages,
  active: boolean
) => {
  const query = gql`
    mutation CreateSubSubInterest(
      $sessionId: Int!
      $sessionToken: String!
      $parent: Int!
      $names: LanguagesInput!
      $active: Boolean!
    ) {
      createSubSubInterest(
        sessionId: $sessionId
        sessionToken: $sessionToken
        parent: $parent
        names: $names
        active: $active
      )
    }
  `;
  return await queryGraphQL<boolean>(
    query,
    { sessionId: user.id, sessionToken: user.token, parent, names, active },
    "createSubSubInterest"
  );
};

export const updateInterest = async (
  user: User,
  id: number,
  color: string,
  names: Languages,
  active: boolean
) => {
  const query = gql`
    mutation UpdateInterest(
      $sessionId: Int!
      $sessionToken: String!
      $color: String!
      $id: Int!
      $names: LanguagesInput!
      $active: Boolean!
    ) {
      updateInterest(
        sessionId: $sessionId
        sessionToken: $sessionToken
        id: $id
        color: $color
        names: $names
        active: $active
      )
    }
  `;
  return await queryGraphQL<boolean>(
    query,
    { sessionId: user.id, sessionToken: user.token, color, id, names, active },
    "updateInterest"
  );
};

export const updateSubInterest = async (
  user: User,
  id: number,
  parent: number,
  names: Languages,
  active: boolean
) => {
  const query = gql`
    mutation UpdateSubInterest(
      $sessionId: Int!
      $sessionToken: String!
      $parent: Int!
      $id: Int!
      $names: LanguagesInput!
      $active: Boolean!
    ) {
      updateSubInterest(
        sessionId: $sessionId
        sessionToken: $sessionToken
        id: $id
        parent: $parent
        names: $names
        active: $active
      )
    }
  `;
  return await queryGraphQL<boolean>(
    query,
    { sessionId: user.id, sessionToken: user.token, parent, id, names, active },
    "updateSubInterest"
  );
};

export const updateSubSubInterest = async (
  user: User,
  id: number,
  parent: number,
  names: Languages,
  active: boolean
) => {
  const query = gql`
    mutation UpdateSubSubInterest(
      $sessionId: Int!
      $sessionToken: String!
      $parent: Int!
      $id: Int!
      $names: LanguagesInput!
      $active: Boolean!
    ) {
      updateSubSubInterest(
        sessionId: $sessionId
        sessionToken: $sessionToken
        id: $id
        parent: $parent
        names: $names
        active: $active
      )
    }
  `;
  return await queryGraphQL<boolean>(
    query,
    { sessionId: user.id, sessionToken: user.token, parent, id, names, active },
    "updateSubSubInterest"
  );
};

export interface InterestsSuggestion {
  response: string;
  ids: Array<number[]>;
}

export const suggestInterests = async (
  user: User,
  title: string,
  description: string,
) => {
  const graphqlQuery = gql`
    query SuggestInterests(
      $sessionId: Int!
      $sessionToken: String!
      $title: String!
      $description: String!
    ) {
      suggestInterests(
        sessionId: $sessionId
        sessionToken: $sessionToken
        title: $title
        description: $description
      ) {
        response
        ids
      }
    }
  `;
  return await queryGraphQL<InterestsSuggestion>(
    graphqlQuery,
    { sessionId: user.id, sessionToken: user.token, title, description },
    "suggestInterests"
  );
};