import { gql } from "graphql-request";
import { queryGraphQL } from "./backend";
import { Language } from "./languages";
import { User } from "./users";

export interface EditConnectorRecord {
  id?: number;
  rssJsonConnector?: RssJsonConnector;
  tempoSecs: number;
  fetchPeriodMinutes: number;
  language: Language;
}

export interface ConnectorRecord {
  id: number;
  rssJsonConnector?: RssJsonConnector;
  tempoSecs: number;
  fetchPeriodMinutes: number;
  language: Language;
  active: boolean;
  contentSize: number;
}

export interface RssJsonConnector {
  url: string;
  maxSize: number;
  textCssSelector?: string;
}

export const getConnectors = async (
  user: User,
  from: number,
  size: number
) => {
  const query = gql`
    query GetConnectors($sessionId: Int!, $sessionToken: String!, $from: Int!, $size: Int!) {
      getConnectors(sessionId: $sessionId, sessionToken: $sessionToken, from: $from, size: $size) {
        id
        rssJsonConnector {
          url
          maxSize
          textCssSelector
        }
        tempoSecs
        fetchPeriodMinutes
        language
        active
        contentSize
      }
    }
  `;
  return await queryGraphQL<Array<ConnectorRecord>>(
    query,
    { sessionId:user.id, sessionToken: user.token, from, size },
    "getConnectors"
  );
};

export interface RssJsonInput {
  rssUrl: string;
  maxSize: number;
  textCssSelector?: string;
}

export const upsertRssJsonConnector = async (
  user: User,
  connectorId: number | undefined,
  language: Language,
  tempoSecs: number,
  fetchPeriodMinutes: number,
  input: RssJsonInput
) => {
  const query = gql`
    mutation UpsertRssJsonConnector(
      $sessionId: Int!
      $sessionToken: String!
      $connectorId: Int
      $language: Language!
      $fetchPeriodMinutes: Int!
      $tempoSecs: Int!
      $input: RssJsonInput!
    ) {
      upsertRssJsonConnector(
        sessionId: $sessionId
        sessionToken: $sessionToken
        connectorId: $connectorId
        language: $language
        fetchPeriodMinutes: $fetchPeriodMinutes
        tempoSecs: $tempoSecs
        input: $input
      )
    }
  `;
  return await queryGraphQL<boolean>(
    query,
    { sessionId:user.id, sessionToken: user.token, connectorId, language, fetchPeriodMinutes, tempoSecs, input },
    "upsertRssJsonConnector"
  );
};

export const setConnectorActive = async (
  user: User,
  connectorId: number,
  active: boolean
) => {
  const query = gql`
    mutation SetConnectorActive(
      $sessionId: Int!
      $sessionToken: String!
      $connectorId: Int!
      $active: Boolean!
    ) {
      setConnectorActive(
        sessionId: $sessionId
        sessionToken: $sessionToken
        connectorId: $connectorId
        active: $active
      )
    }
  `;
  return await queryGraphQL<boolean>(
    query,
    { sessionId:user.id, sessionToken: user.token, connectorId, active },
    "setConnectorActive"
  );
};

export const deleteConnectorRecord = async (
  user: User,
  connectorId: number
) => {
  const query = gql`
    mutation DeleteConnector($sessionId: Int!, $sessionToken: String!, $connectorId: Int!) {
      deleteConnector(sessionId: $sessionId, sessionToken: $sessionToken, connectorId: $connectorId)
    }
  `;
  return await queryGraphQL<boolean>(
    query,
    { sessionId:user.id, sessionToken: user.token, connectorId },
    "deleteConnector"
  );
};
