import { Login } from "./components/login";
import { Box } from "@mui/material";
import { useAppSelector } from "./store";
import { View } from "./views";
import { AppMenu } from "./components/appmenu";
import { ErrorAlert } from "./components/erroralert";
import { COLORS } from "./theme";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";

TimeAgo.addDefaultLocale(en);

function App() {
  const user = useAppSelector((state) => state.session.user);

  return (
    <Box display="flex" style={{ height: "100vh" }}>
      <Box
        style={{ backgroundColor: COLORS.panelBackground }}
        display="flex"
        flexDirection={"column"}
      >
        <Box flexGrow={1}>{user && <AppMenu />}</Box>
        <Box padding={2}>
          <Login />
        </Box>
      </Box>
      <Box
        flexGrow={1}
        padding={2}
        style={{ backgroundColor: COLORS.mainBackground }}
      >
        <ErrorAlert />
        {user && <View />}
      </Box>
    </Box>
  );
}

export default App;
