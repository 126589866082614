import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export interface AlertDialogProps {
  title: string;
  content: string;
  close: string;
  ok: string;
  onClose: () => void;
  onOk: () => void;
}

export default function AlertDialog({
  title,
  content,
  close,
  ok,
  onClose,
  onOk,
}: AlertDialogProps) {
  return (
    <Dialog open={true}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{close}</Button>
        <Button onClick={onOk} autoFocus>
          {ok}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
