export enum Language {
  EN = "EN",
  ES = "ES",
  FR = "FR",
  UKR = "UKR",
}

export enum LanguageLabels {
  en = "English",
  es = "Spanish",
  fr = "French",
  ukr = "Ukrainian",
}

export interface Languages {
  en: string;
  es: string;
  fr: string;
  ukr: string;
}

export type LanguageKeys = keyof Languages;
