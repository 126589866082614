export const COLORS = {
  mainButton: "#485CF3",
  mainBackground: "#EEEEEE",
  panelBackground: "#FFFFFF",
  badgeText: "#FFFFFF",
  countBackground: "#E1E1FA99",
  inactiveNav: "#7D7D7D",
};

export const SUB_COLORS = {
  Sunny: "#F9F871",
  Salmon: "#FF8869",
  BrilliantRose: "#FF5098",
  SteelPink: "#DE44CE",
  PastelRed: "#FE6061",
  VeryLightGreen: "#54FF98",
  Green: "#009B6D",
  Maize: "#FFC354",
  ViridianGreen: "#0099A2",
};
