import { Paper, Stack, TextField, Typography } from "@mui/material";
import { ChangeEvent, useCallback, useState } from "react";
import { CancelButton, MainButton } from "../components/buttons";
import { FormGrid, GridItem1, GridItem2, GridItem8 } from "../components/grids";
import { useAppDispatch, useAppSelector } from "../store";
import { setLastError, setView, Views } from "../store/session";
import { upsertRssJsonConnector } from "../model/connectors";
import { Language } from "../model/languages";
import { LanguageMenu } from "../components/LanguageMenu";

export const EditConnector = () => {
  const user = useAppSelector((state) => state.session.user);
  const connector = useAppSelector((state) => state.session.editConnector);
  const dispatch = useAppDispatch();
  const [rssUrl, setRssUrl] = useState(connector?.rssJsonConnector?.url || "");
  const [maxSize, setMaxSize] = useState(
    connector?.rssJsonConnector?.maxSize || 20
  );
  const [textCssSelector, setTextCssSelector] = useState(
    connector?.rssJsonConnector?.textCssSelector
  );
  const [tempoSecs, setTempoSecs] = useState(connector?.tempoSecs || 1);
  const [fetchPeriodMinutes, setFetchPeriodMinutes] = useState(
    connector?.fetchPeriodMinutes || 1
  );
  const [language, setLanguage] = useState(connector?.language || Language.EN);

  const handleCancel = useCallback(() => {
    dispatch(setView(Views.Connectors));
  }, [dispatch]);

  const handleSave = useCallback(async () => {
    if (user) {
      if (connector && connector.rssJsonConnector) {
        dispatch(setLastError());
        try {
          await upsertRssJsonConnector(
            user,
            connector.id,
            language,
            tempoSecs,
            fetchPeriodMinutes,
            {
              rssUrl,
              maxSize,
              textCssSelector
            }
          );
          handleCancel();
        } catch (err) {
          dispatch(setLastError(String(err)));
        }
      }
    }
  }, [connector, dispatch, fetchPeriodMinutes, handleCancel, language, maxSize, rssUrl, user, tempoSecs, textCssSelector]);

  const handleChangeRssUrl = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setRssUrl(event.currentTarget.value);
    },
    [setRssUrl]
  );

  const handleChangeLanguage = useCallback(
    (lang: Language) => {
      setLanguage(lang);
    },
    [setLanguage]
  );

  const handleChangeMaxSize = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setMaxSize(Number(event.currentTarget.value));
    },
    [setMaxSize]
  );

  const handleChangeTextCssSelector = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setTextCssSelector(event.currentTarget.value);
    },
    [setTextCssSelector]
  );

  const handleChangeTempoSecs = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setTempoSecs(Number(event.currentTarget.value));
    },
    [setTempoSecs]
  );

  const handleChangeFetchPeriodMinutes = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setFetchPeriodMinutes(Number(event.currentTarget.value));
    },
    [setFetchPeriodMinutes]
  );

  let editVerb = connector?.id
    ? `Edit connector ${connector?.id}`
    : "New connector";

  return (
    <Stack spacing={1}>
      <Paper>
        <Stack spacing={1} padding={2}>
          <Stack direction={"row"} justifyContent="space-between">
            <Stack direction={"row"} spacing={1}>
              <Typography variant="h4">{editVerb}</Typography>
            </Stack>
          </Stack>
          <br />
          <FormGrid>
            <GridItem8>
              <TextField
                fullWidth
                id="feed-url"
                label="Feed URL"
                type={"url"}
                value={rssUrl}
                onChange={handleChangeRssUrl}
              />
            </GridItem8>
            <GridItem1>
              <LanguageMenu
                fullWidth
                language={language}
                onChange={handleChangeLanguage}
              />
            </GridItem1>
            <GridItem1>
              <TextField
                fullWidth
                id="max-size"
                label="Feed max size"
                type={"number"}
                value={maxSize}
                onChange={handleChangeMaxSize}
              />
            </GridItem1>
            <GridItem2>
              <TextField
                fullWidth
                id="text-css-selector"
                label="Text CSS Selector"
                type={"text"}
                value={textCssSelector}
                onChange={handleChangeTextCssSelector}
              />
            </GridItem2>
            <GridItem1>
              <TextField
                fullWidth
                id="tempo-secs"
                label="Temporisation (seconds)"
                type={"number"}
                value={tempoSecs}
                onChange={handleChangeTempoSecs}
              />
            </GridItem1>
            <GridItem1>
              <TextField
                fullWidth
                id="fetch-period-minutes"
                label="Fetch period (minutes)"
                type={"number"}
                value={fetchPeriodMinutes}
                onChange={handleChangeFetchPeriodMinutes}
              />
            </GridItem1>
          </FormGrid>
          <Stack spacing={1} direction="row-reverse">
            <MainButton variant="contained" onClick={handleSave}>
              {connector?.id ? "Save" : "Create"}
            </MainButton>
            <CancelButton variant="outlined" onClick={handleCancel}>
              Cancel
            </CancelButton>
          </Stack>
        </Stack>
      </Paper>
    </Stack>
  );
};
