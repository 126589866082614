import {
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useCallback } from "react";

interface SelectMenuProps {
  id: string;
  label: string;
  value: string | undefined;
  fullWidth?: boolean;
  onChange: (value: string) => void;
  children?: React.ReactNode;
}

export const SelectMenu = ({
  id,
  label,
  value,
  fullWidth,
  onChange,
  children,
}: SelectMenuProps) => {
  
  const handleChange = useCallback(
    (event: SelectChangeEvent) => {
      onChange(event.target.value);
    },
    [onChange]
  );
  return (
    <FormControl fullWidth={fullWidth || false}>
      <InputLabel id={`${id}-label`}>{label}</InputLabel>
      <Select
        labelId={`${id}-label`}
        id={`${id}-select`}
        value={value || ''}
        label={label}
        onChange={handleChange}
      >
        {children}
      </Select>
    </FormControl>
  );
};
