import Grid2, { Grid2Props } from "@mui/material/Unstable_Grid2";

export const GridItem1 = ({ xs, md, lg, xl, ...rest }: Grid2Props) => {
  return <Grid2 xs={1} md={1} lg={1} xl={1} {...rest} />;
};

export const GridItem2 = ({ xs, md, lg, xl, ...rest }: Grid2Props) => {
  return <Grid2 xs={1} md={1} lg={1} xl={2} {...rest} />;
};

export const GridItem4 = ({ xs, md, lg, xl, ...rest }: Grid2Props) => {
  return <Grid2 xs={1} md={1} lg={2} xl={4} {...rest} />;
};

export const GridItem8 = ({ xs, md, lg, xl, ...rest }: Grid2Props) => {
  return <Grid2 xs={1} md={2} lg={4} xl={8} {...rest} />;
};

export const FormGrid = ({ xs, md, lg, xl, ...rest }: Grid2Props) => {
  return (
    <Grid2
      container
      columns={{ xs: 1, md: 2, lg: 4, xl: 8 }}
      spacing={{ xs: 1, md: 1, lg: 1, xl: 1 }}
      {...rest}
    />
  );
};
