import { TableCell, Typography } from "@mui/material";

export const TableLabel = ({
  align,
  label,
}: {
  align?: "right" | "left" | "center";
  label: string;
}) => {
  return (
    <TableCell align={align}>
      <Typography fontWeight="bold">{label}</Typography>
    </TableCell>
  );
};
