import { useAppDispatch, useAppSelector } from "../store";
import { useCallback, useEffect, useState } from "react";
import {
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { EditActivity, editActivity, setLastError } from "../store/session";
import { TableLabel } from "../components/tables";
import EditIcon from "@mui/icons-material/Edit";
import { Activity, getBoActivities } from "../model/activities";
import { MainButton } from "../components/buttons";

export const DigitalDetox = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.session.user);
  const [activities, setActivities] = useState<Activity[]>([]);

  const loadActivities = useCallback(async () => {
    if (user) {
      dispatch(setLastError());

      {
        const { data, error } = await getBoActivities(user, true);

        if (error) {
          dispatch(setLastError(error));
        }
        setActivities(data || []);
      }
    }
  }, [dispatch, user]);

  useEffect(() => {
    loadActivities().catch((err) => dispatch(setLastError(err)));
  }, [dispatch, loadActivities]);

  const onEditActivity = useCallback(
    (activity: EditActivity) => {
      dispatch(
        editActivity(activity)
      );
    },
    [dispatch]
  );

  return (
    <Stack spacing={2}>
      <Stack direction={"row"} alignItems="center">
        <Typography variant="h4">Activities</Typography>
      </Stack>
      <Stack spacing={2} direction={"row"}>
        <MainButton onClick={()=>onEditActivity({ names: {en: '', es: '', fr:'', ukr: ''}, active: false})}>Add new</MainButton>
      </Stack>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableLabel label="English" />
              <TableLabel label="Spanish" />
              <TableLabel label="French" />
              <TableLabel label="Ukrainian" />
              <TableLabel label="Enabled" />
              <TableLabel label="Actions"/>
            </TableRow>
          </TableHead>
          <TableBody>
            {activities.map((activity) => (
              <TableRow
                key={activity.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <Typography>{activity.names.en}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{activity.names.es}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{activity.names.fr}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{activity.names.ukr}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{activity.active? 'true' : 'false'}</Typography>
                </TableCell>
                <TableCell>
                  <Stack
                    direction={"row"}
                    spacing={1}
                    justifyContent={"center"}
                  >
                    <Button
                      onClick={() => onEditActivity(activity)}
                      color="primary"
                      variant="contained"
                      startIcon={<EditIcon />}
                    >
                      Edit
                    </Button>
                    </Stack>
                    </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};