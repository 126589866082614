import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EditConnectorRecord } from "../model/connectors";
import { Language, Languages } from "../model/languages";
import { User } from "../model/users";
import { PostInput } from "../model/posts";

export enum Views {
  Dashboard = "dashboard",
  Interests = "interests",
  InterestRequests = "Requested",
  EditInterest = "editInterest",
  EditConnector = "editConnector",
  Connectors = "Connectors",
  EditPost = "editPost",
  EditPostInterests = "editPostInterests",
  PendingPosts = "Pending Posts",
  LivePosts = "Live Posts",
  DigitalDetox = "Digital Detox",
  EditActivity = "editActivity",
  ActiveUsers = "active users",
}

export type SessionState = {
  user?: User;
  view: Views;
  editInterest?: EditInterest;
  editConnector?: EditConnectorRecord;
  editPost?: EditPost;
  editActivity?: EditActivity;
  interestSearch?: string;
  language?: Language;
  lastError?: string;
};

export type InterestType = 'main' | 'sub' | 'subsub';

export interface EditInterest {
  id?: number;
  parentId?: number;
  type: InterestType;
}

export interface EditPost {
  post: PostInput,
  edit: 'content' | 'interests',
  back: 'live' | 'pending'
}

export interface EditActivity {
  id?: number;
  names: Languages,
  active: boolean
}

const gifviewLocalStorageName = "gifview_bo_state";

const initialState = (): SessionState => {
  const state = localStorage.getItem(gifviewLocalStorageName);
  if (state) {
    return JSON.parse(state);
  }
  return {
    view: Views.Dashboard,
    language: Language.EN,
  };
};

const saveState = (state: SessionState) => {
  localStorage.setItem(gifviewLocalStorageName, JSON.stringify(state));
};

const removeState = () => {
  localStorage.removeItem(gifviewLocalStorageName);
};

export const sessionSlice = createSlice({
  name: "session",
  initialState: initialState(),
  reducers: {
    login: (state, action: PayloadAction<User | undefined>) => {
      if (action.payload) {
        state.user = action.payload;
        saveState(state);
      }
    },
    logout: (state) => {
      state.user = undefined;
      removeState();
    },
    setView: (state, action: PayloadAction<Views>) => {
      state.view = action.payload;
      saveState(state);
    },
    editInterest: (state, action: PayloadAction<EditInterest | undefined>) => {
      state.view = Views.EditInterest;
      state.editInterest = action.payload;
      saveState(state);
    },
    editConnector: (
      state,
      action: PayloadAction<EditConnectorRecord | undefined>
    ) => {
      state.view = Views.EditConnector;
      state.editConnector = action.payload;
      saveState(state);
    },
    editPost: (
      state,
      action: PayloadAction<EditPost | undefined>
    ) => {
      state.view = action.payload?.edit === 'content' ? Views.EditPost : Views.EditPostInterests;
      state.editPost = action.payload;
      saveState(state);
    },
    setLastError: (state, action: PayloadAction<string | undefined>) => {
      if (action.payload && action.payload.startsWith("NonExistingSession")) {
        state.lastError = undefined;
        state.user = undefined;
      } else {
        state.lastError = action.payload;
      }
    },
    setLanguage: (state, action: PayloadAction<Language>) => {
      state.language = action.payload;
      saveState(state);
    },
    setInterestSearch: (state, action: PayloadAction<string>) => {
      state.interestSearch = action.payload;
      saveState(state);
    },
    editActivity: (state, action: PayloadAction<EditActivity | undefined>) => {
      state.view = Views.EditActivity;
      state.editActivity = action.payload;
      saveState(state);
    },
  },
});

export const {
  login,
  logout,
  setView,
  editInterest,
  editConnector,
  editPost,
  setLastError,
  setLanguage,
  setInterestSearch,
  editActivity,
} = sessionSlice.actions;
