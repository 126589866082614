import { useAppDispatch, useAppSelector } from "../store";
import { Button, Stack } from "@mui/material";
import Item from "@mui/material/Stack";
import { setView, Views, setLastError } from "../store/session";
import { Privilege } from "../model/users";
import { ReactNode } from "react";
import GridViewIcon from "@mui/icons-material/GridView";
import DomainAddIcon from "@mui/icons-material/DomainAdd";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import Quiz from "@mui/icons-material/Quiz";
import { COLORS } from "../theme";
import { RssFeed } from "@mui/icons-material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import AirIcon from '@mui/icons-material/Air';

const NavButton = ({ view, icon }: { view: Views; icon: ReactNode }) => {
  const currentView = useAppSelector((state) => state.session.view);
  const dispatch = useAppDispatch();

  const buttonColor =
    currentView === view ? COLORS.mainButton : COLORS.inactiveNav;

  return (
    <Button
      startIcon={icon}
      variant="text"
      style={{
        color: buttonColor,
        textTransform: "capitalize",
        alignSelf: "flex-start",
      }}
      onClick={() => {
        dispatch(setLastError());
        dispatch(setView(view));
      }}
    >
      {view}
    </Button>
  );
};

export const AppMenu = () => {
  const privileges = useAppSelector((state) => state.session.user?.privileges);

  const isAdmin = privileges?.indexOf(Privilege.Admin) !== -1;

  return (
    <Stack padding={1}>
      <Item margin={1} alignItems={"center"}>
        <img src="/logo85.png" alt="Gifview logo" width="85px" />
      </Item>
      <Item marginBottom={4} alignItems={"center"}>
        <img src="/gifview.png" alt="Gifview" width="120px" />
      </Item>
      <Item paddingLeft={2}>
        <NavButton view={Views.Dashboard} icon={<GridViewIcon />} />
        <NavButton view={Views.Interests} icon={<DomainAddIcon />} />
        <NavButton view={Views.InterestRequests} icon={<Quiz />} />
        <NavButton view={Views.Connectors} icon={<RssFeed />} />
        <NavButton view={Views.PendingPosts} icon={<StarIcon />} />
        <NavButton view={Views.LivePosts} icon={<StarBorderIcon />} />
        <NavButton view={Views.DigitalDetox} icon={<AirIcon/>} />
        {isAdmin && (
          <Item>
            <NavButton view={Views.ActiveUsers} icon={<PeopleAltIcon />} />
          </Item>
        )}{" "}
      </Item>
    </Stack>
  );
};
