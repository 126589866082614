import { useAppDispatch, useAppSelector } from "../store";
import { useCallback, useEffect, useState } from "react";
import {
  ConnectorRecord,
  deleteConnectorRecord,
  getConnectors,
  setConnectorActive,
} from "../model/connectors";
import {
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import AlertDialog from "../components/AlertDialog";
import { editConnector, setLastError } from "../store/session";
import { Language, LanguageKeys, LanguageLabels } from "../model/languages";
import { MainButton } from "../components/buttons";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FlashOffIcon from "@mui/icons-material/FlashOff";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import { TableLabel } from "../components/tables";

const PAGE_SIZE = 50;

export const Connectors = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.session.user);
  const [from] = useState(0);
  const [connectors, setConnectors] = useState<ConnectorRecord[]>([]);
  const [deleteConnectorId, setDeleteConnectorId] = useState<number>();

  const loadConnectors = useCallback(async () => {
    if (user) {
      dispatch(setLastError());

      {
        const { data, error } = await getConnectors(user, from, PAGE_SIZE);
        if (error) {
          dispatch(setLastError(error));
        }
        setConnectors(data || []);
      }
    }
  }, [dispatch, from, user]);

  const handleAddNew = useCallback(() => {
    dispatch(
      editConnector({
        tempoSecs: 1,
        fetchPeriodMinutes: 5,
        language: Language.EN,
        rssJsonConnector: { url: "", maxSize: 20 },
      })
    );
  }, [dispatch]);

  const onEditConnector = useCallback(
    (connector: ConnectorRecord) => {
      dispatch(
        editConnector({
          id: connector.id,
          tempoSecs: connector.tempoSecs,
          fetchPeriodMinutes: connector.fetchPeriodMinutes,
          language: connector.language,
          rssJsonConnector: connector.rssJsonConnector,
        })
      );
    },
    [dispatch]
  );

  const connectorActivation = useCallback(
    async (connectorId: number, active: boolean) => {
      if (user && connectorId) {
        dispatch(setLastError());
        const { error } = await setConnectorActive(
          user,
          connectorId,
          active
        );
        if (error) {
          dispatch(setLastError(error));
        } else {
          await loadConnectors();
        }
      }
    },
    [dispatch, loadConnectors, user]
  );

  const deleteConnector = useCallback(async () => {
    if (user && deleteConnectorId) {
      dispatch(setLastError());
      const { data, error } = await deleteConnectorRecord(
        user,
        deleteConnectorId
      );
      if (error) {
        dispatch(setLastError(error));
      } else {
        setDeleteConnectorId(undefined);
        if (data) {
          await loadConnectors();
        }
      }
    }
  }, [deleteConnectorId, dispatch, loadConnectors, user]);

  useEffect(() => {
    loadConnectors().catch((err) => dispatch(setLastError(err)));
  }, [dispatch, loadConnectors]);

  return (
    <Stack spacing={2}>
      <Stack direction={"row"} alignItems="center">
        <Typography variant="h4">Connectors</Typography>
      </Stack>
      <Stack spacing={2} direction={"row"}>
        <MainButton onClick={handleAddNew}>
          Add new RSS/LD/JSON connector
        </MainButton>
      </Stack>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableLabel label="URL" />
              <TableLabel label="Language" align="center" />
              <TableLabel label="Active" align="center" />
              <TableLabel label="Size" align="right" />
              <TableLabel label="Actions" align="center" />
            </TableRow>
          </TableHead>
          <TableBody>
            {connectors.map((connector) => (
              <TableRow
                key={connector.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <Typography>{connector.rssJsonConnector?.url}</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>
                    {
                      LanguageLabels[
                        connector.language.toLocaleLowerCase() as LanguageKeys
                      ]
                    }
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>{String(connector.active)}</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography>{connector.contentSize}</Typography>
                </TableCell>
                <TableCell>
                  <Stack
                    direction={"row"}
                    spacing={1}
                    justifyContent={"center"}
                  >
                    <Button
                      onClick={() => onEditConnector(connector)}
                      color="primary"
                      variant="contained"
                      startIcon={<EditIcon />}
                    >
                      Edit
                    </Button>
                    <Button
                      onClick={() => setDeleteConnectorId(connector.id)}
                      disabled={connector.active}
                      color="error"
                      variant="contained"
                      startIcon={<DeleteIcon />}
                    >
                      Delete
                    </Button>
                    {connector.active ? (
                      <Button
                        onClick={() => connectorActivation(connector.id, false)}
                        color="warning"
                        variant="contained"
                        startIcon={<FlashOffIcon />}
                      >
                        Disable
                      </Button>
                    ) : (
                      <Button
                        onClick={() => connectorActivation(connector.id, true)}
                        color="success"
                        variant="contained"
                        startIcon={<FlashOnIcon />}
                      >
                        Enable
                      </Button>
                    )}
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {deleteConnectorId && (
        <AlertDialog
          close="Cancel"
          ok="Delete"
          title="Connector deletion"
          content="Please confirm that you want to delete the connector."
          onClose={() => setDeleteConnectorId(undefined)}
          onOk={() => deleteConnector()}
        />
      )}
    </Stack>
  );
};
