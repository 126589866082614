import styled from "@emotion/styled";
import { Button, ButtonProps } from "@mui/material";
import { COLORS } from "../theme";

export const MainButton = ({ variant, ...rest }: ButtonProps) => {
  return <StyledMainButton variant="contained" {...rest} />;
};

export const CancelButton = ({ variant, ...rest }: ButtonProps) => {
  return <StyledCancelButton variant="outlined" {...rest} />;
};

export const TextButton = ({ variant, ...rest }: ButtonProps) => {
  return <StyledTextButton variant="text" {...rest} />;
};

const StyledMainButton = styled(Button)<ButtonProps>(() => ({
  backgroundColor: COLORS.mainButton,
  textTransform: "capitalize",
}));

const StyledCancelButton = styled(Button)<ButtonProps>(() => ({
  borderColor: COLORS.mainButton,
  color: COLORS.mainButton,
  textTransform: "capitalize",
}));

const StyledTextButton = styled(Button)<ButtonProps>(() => ({
  color: COLORS.mainButton,
  textTransform: "none",
}));
