import {
    Paper,
    Stack,
    Switch,
    FormControlLabel,
    FormGroup,
    TextField,
    Typography,
  } from "@mui/material";
  import { useCallback, useState } from "react";
  import { CancelButton, MainButton } from "../components/buttons";
  import { FormGrid, GridItem1 } from "../components/grids";
  import { useAppDispatch, useAppSelector } from "../store";
  import { setView, Views } from "../store/session";
import { createActivity, updateActivity } from "../model/activities";

export const EditActivity = () => {
    const user = useAppSelector((state) => state.session.user);
    const activity = useAppSelector((state) => state.session.editActivity);
    const dispatch = useAppDispatch();
    const [nameEn, setNameEn] = useState(activity?.names.en);
    const [nameEs, setNameEs] = useState(activity?.names.es);
    const [nameFr, setNameFr] = useState(activity?.names.fr);
    const [nameUkr, setNameUkr] = useState(activity?.names.ukr);
    const [active, setActive] = useState(activity?.active);
    
    const handleCancel = useCallback(() => {
        dispatch(setView(Views.DigitalDetox));
      }, [dispatch]);
      
      const handleSave = useCallback(async () => {
        if (user) {
          let names = {
            en: nameEn || '',
            es: nameEs || '',
            fr: nameFr || '',
            ukr: nameUkr || '',
          };
          if (activity?.id) {
                await updateActivity(
                  user,
                  activity.id,
                  names,
                  active || false
                );
            } else {
            await createActivity(
                user,
                names,
                active || false
              );
          }
        }
        handleCancel();
      }, [user, handleCancel, nameEn, nameEs, nameFr, nameUkr, activity?.id, active]);

      
    let editVerb = activity?.id ? "Edit" : "Add";

    return (
    <Stack spacing={1}>
      <Paper>
        <Stack spacing={1} padding={2}>
          <Stack direction={"row"} justifyContent="space-between">
            <Stack direction={"row"} spacing={1}>
              <Typography variant="h4">{editVerb}</Typography>
              <Typography variant="h4">{nameEn}</Typography>
            </Stack>
          </Stack>
          <br />
          <FormGrid>
            <GridItem1>
              <TextField
                fullWidth
                id="name-en"
                label="English"
                value={nameEn}
                onChange={(event) => setNameEn(event.currentTarget.value)}
              />
            </GridItem1>
            <GridItem1>
              <TextField
                fullWidth
                id="name-es"
                label="Spanish"
                value={nameEs}
                onChange={(event) => setNameEs(event.currentTarget.value)}
              />
            </GridItem1>
            <GridItem1>
              <TextField
                fullWidth
                id="name-fr"
                label="French"
                value={nameFr}
                onChange={(event) => setNameFr(event.currentTarget.value)}
              />
            </GridItem1>
            <GridItem1>
              <TextField
                fullWidth
                id="name-ukr"
                label="Ukrainian"
                value={nameUkr}
                onChange={(event) => setNameUkr(event.currentTarget.value)}
              />
            </GridItem1>
            <GridItem1>
              <FormGroup>
                <FormControlLabel
                  labelPlacement="top"
                  control={
                    <Switch checked={active} onChange={(event) => setActive(event.target.checked)} />
                  }
                  label="Active"
                />
              </FormGroup>
            </GridItem1>
          </FormGrid>
          <Stack spacing={1} direction="row-reverse">
            <MainButton variant="contained" onClick={handleSave}>
              {activity?.id ? "Save" : "Create"}
            </MainButton>
            <CancelButton variant="outlined" onClick={handleCancel}>
              Cancel
            </CancelButton>
          </Stack>
        </Stack>
      </Paper>
    </Stack>
  );
}