import { MenuItem } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { getInterests, getSubInterests, getSubSubInterests, Interest } from "../model/interests";
import { useAppDispatch, useAppSelector } from "../store";
import { InterestType, setLastError } from "../store/session";
import { SelectMenu } from "./selectmenu";

export const InterestsMenu = ({
  parentId,
  interestType,
  selectedInterestId,
  label,
  fullWidth,
  onChange,
}: {
  parentId?: number;
  interestType: InterestType,
  selectedInterestId?: number;
  label: string;
  fullWidth?: boolean;
  onChange: (interest_id: number) => void;
}) => {
  const dispatch = useAppDispatch();
  const [interests, setInterests] = useState<Interest[]>([]);
  const user = useAppSelector((state) => state.session.user);

  const loadInterests = useCallback(async () => {
    if (user) {
      dispatch(setLastError());
      let data, error;
      switch (interestType) {
        case 'main':
          const { data:idata, error:ierror } = await getInterests(user, true);
          data = idata;
          error = ierror;
          break;
        case 'sub':
          if (parentId) {
            const { data:sidata, error:sierror } = await getSubInterests(user, parentId, true);
            data = sidata;
            error = sierror;
          }
          break;
        case 'subsub':
          if (parentId) {
            const { data:ssidata, error:ssierror } = await getSubSubInterests(user, parentId, true);
            data = ssidata;
            error = ssierror;
          }
          break;
      }

      if (data) {
        setInterests(data);
      }
      if (error) {
        dispatch(setLastError(error));
      }
    }
  }, [dispatch, interestType, parentId, user]);

  useEffect(() => {
    loadInterests().catch((err) => dispatch(setLastError(err)));
  }, [dispatch, loadInterests]);

  return (
    <SelectMenu
      fullWidth={fullWidth}
      id="lang"
      value={selectedInterestId?.toString()}
      label={label}
      onChange={(value) => onChange(Number(value))}
    >
      {interests.map((interest) => (
        <MenuItem key={interest.id} value={interest.id}>
          {interest.names.en}
        </MenuItem>
      ))}
    </SelectMenu>
  );
};
