import { gql } from "graphql-request";
import { queryGraphQL } from "./backend";
import { Languages } from "./languages";
import { User } from "./users";

export interface Activity {
  id: number;
  names: Languages;
  active: boolean;
  gifId: number;
}

export const getBoActivities = async (user: User, includeInactive: boolean) => {
  const query = gql`
    query GetBoActivities($sessionId: Int!, $sessionToken: String!, $includeInactive: Boolean!) {
      getBoActivities(sessionId: $sessionId, sessionToken: $sessionToken, includeInactive: $includeInactive) {
        id
        names {
          en
          es
          fr
          ukr
        }
        active
        gifId
      }
    }
  `;
  return await queryGraphQL<Array<Activity>>(
    query,
    { sessionId: user.id, sessionToken: user.token, includeInactive },
    "getBoActivities"
  );
};

export const createActivity = async (
  user: User,
  names: Languages,
  active: boolean
) => {
  const query = gql`
    mutation CreateActivity(
      $sessionId: Int!
      $sessionToken: String!
      $names: LanguagesInput!
      $active: Boolean!
    ) {
      createActivity(
        sessionId: $sessionId
        sessionToken: $sessionToken
        names: $names
        active: $active
      )
    }
  `;
  return await queryGraphQL<boolean>(
    query,
    { sessionId: user.id, sessionToken: user.token, names, active },
    "createActivity"
  );
};

export const updateActivity = async (
  user: User,
  id: number,
  names: Languages,
  active: boolean
) => {
  const query = gql`
    mutation UpdateActivity(
      $sessionId: Int!
      $sessionToken: String!
      $id: Int!
      $names: LanguagesInput!
      $active: Boolean!
    ) {
      updateActivity(
        sessionId: $sessionId
        sessionToken: $sessionToken
        id: $id
        names: $names
        active: $active
      )
    }
  `;
  return await queryGraphQL<boolean>(
    query,
    { sessionId: user.id, sessionToken: user.token, id, names, active },
    "updateActivity"
  );
};