import {
  LinearProgress,
  Link,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { CancelButton, MainButton, TextButton } from "../components/buttons";
import { FormGrid, GridItem4, GridItem1 } from "../components/grids";
import { useAppDispatch, useAppSelector } from "../store";
import { setLastError, setView, Views } from "../store/session";
import { Language } from "../model/languages";
import { suggestTopic, upsertPost } from "../model/posts";
import { LanguageMenu } from "../components/LanguageMenu";
import { getGif, Gif, GifSuggestion, insertGif, suggestBestGif } from "../model/gifs";
import { GiphyEmbed } from "../components/GiphyEmbed";

export const EditPost = () => {
  const user = useAppSelector((state) => state.session.user);
  const editPost = useAppSelector((state) => state.session.editPost);
  const dispatch = useAppDispatch();
  const [connectorId] = useState(editPost?.post.connectorId);
  const [gif, setGif] = useState<Gif>();
  const [source, setSource] = useState(editPost?.post.source);
  const [title, setTitle] = useState(editPost?.post.title);
  const [description, setDescription] = useState(editPost?.post.description);
  const [content, setContent] = useState(editPost?.post.content);
  const [topic] = useState(editPost?.post.topic);
  const [tags, setTags] = useState(editPost?.post.tags?.join("\n"));
  const [language, setLanguage] = useState(editPost?.post.language);
  const [keywordsSuggestion, setKeywordsSuggestion] = useState<string>();
  const [bestGifSuggestion, setBestGifSuggestion] = useState<GifSuggestion>();
  const [loadingKeywordsSuggestion, setLoadingKeywordsSuggestion] = useState(false);
  const [loadingBestGifSuggestion, setLoadingBestGifSuggestion] = useState(false);

  const handleCancel = useCallback(() => {
    if (editPost?.back === 'live') {
      dispatch(setView(Views.LivePosts));
    } else {
      dispatch(setView(Views.PendingPosts));
    }
  }, [dispatch, editPost?.back]);

  const handleSave = useCallback(async () => {
    if (user && editPost) {
      dispatch(setLastError());
      console.log(`handleSave`);
      try {
        var gifId = editPost.post.gifId;
        if (bestGifSuggestion) {
          const { data, error } = await insertGif(user, {
            provider: bestGifSuggestion.provider,
            providerId: bestGifSuggestion.providerId,
            embedUrl: bestGifSuggestion.embedUrl,
          });
          if (error) {
            throw error;
          }
          console.log(`InsertGIF returns  ${data}`);
          gifId = data;
        }

        await upsertPost(user, {
          id: editPost.post.id,
          connectorId: connectorId || 0,
          gifId,
          source: source || "",
          title: title || "",
          description: description || "",
          content: content || undefined,
          topic: topic || "",
          tags: tags?.split("\n").map((s) => s.trim()) || [],
          language: language || Language.EN,
        });
        handleCancel();
      } catch (err) {
        dispatch(setLastError(String(err)));
      }
    }
  }, [user, editPost, dispatch, bestGifSuggestion, connectorId, source, title, description, content, topic, tags, language, handleCancel]);


    const handleSuggestKeywords = useCallback(async () => {
      if (user && title && description) {
        setKeywordsSuggestion(undefined);
        dispatch(setLastError());
        try {
          setLoadingKeywordsSuggestion(true);
          const {data} = await suggestTopic(user, title, description, content);
          setKeywordsSuggestion(data);
        } catch (err) {
          dispatch(setLastError(String(err)));
        } finally {
          setLoadingKeywordsSuggestion(false);     
        }
      }
      }, [content, description, dispatch, user, title]);

      const handleSuggestBestGif = useCallback(async () => {
        if (user && keywordsSuggestion && title && description) {
          setBestGifSuggestion(undefined);
          dispatch(setLastError());
          try {
            setLoadingBestGifSuggestion(true);
            const {data} = await suggestBestGif(user, keywordsSuggestion, title, description, Language.EN);
            setBestGifSuggestion(data);
          } catch (err) {
            dispatch(setLastError(String(err)));
          } finally {
            setLoadingBestGifSuggestion(false);
          }
        }
        }, [description, dispatch, keywordsSuggestion, user, title]);

        const loadGif = useCallback(async () => {
          console.log(`LoadGIF ${editPost?.post.gifId}`)
          if (user && editPost?.post.gifId) {
            dispatch(setLastError());
            try {
              const {data, error} = await getGif(user, editPost?.post.gifId);
              if (error) {
                throw error;
              }
              setGif(data);
            } catch (err) {
              dispatch(setLastError(String(err)));
            }
          }
          }, [dispatch, editPost?.post.gifId, user]);

        useEffect(()=>{
          loadGif();
        },[loadGif])

  let editVerb = editPost?.post.id
    ? `Edit post ${editPost?.post.id}`
    : "New post";

  return (
    <Stack spacing={1}>
      <Paper>
        <Stack spacing={1} padding={2}>
          <Stack direction={"row"} justifyContent="space-between">
            <Stack direction={"row"} spacing={1}>
              <Typography variant="h4">{editVerb}</Typography>
            </Stack>
          </Stack>
          <br />
          <FormGrid>
            <GridItem4>
              <Stack spacing={2}>
              <TextField
                fullWidth
                id="title"
                label="Title"
                type={"text"}
                value={title}
                onChange={(event) => setTitle(event.target.value)}
              />
               <TextField
                fullWidth
                id="description"
                label="Description"
                type={"text"}
                value={description}
                onChange={(event) => setDescription(event.target.value)}
              />
               <TextField
                fullWidth
                multiline
                rows={10}
                id="content"
                label="Content"
                type={"text"}
                value={content}
                onChange={(event) => setContent(event.target.value)}
              />
              <TextField
                  fullWidth
                  id="source"
                  label="Source"
                  type={"text"}
                  value={source}
                  onChange={(event) => setSource(event.target.value)}
                />
                 <Typography variant="body2">
                  <Link href={source} target={"_blank"}>{source}</Link>
                </Typography>
                <TextField
                fullWidth
                id="tags"
                label="Tags"
                multiline
                type={"text"}
                value={tags}
                onChange={(event) => setTags(event.target.value)}
              />
              </Stack>
            </GridItem4>
            <GridItem4>
            {gif &&  <GiphyEmbed embedUrl={gif.embedUrl}/>}
            </GridItem4>
            </FormGrid>
            <FormGrid>
            <GridItem1>
            <LanguageMenu
                fullWidth
                language={language || Language.EN}
                onChange={setLanguage}
              />
              </GridItem1>
              </FormGrid>
          <FormGrid>
            <GridItem4>
              <Stack>
                <TextButton disabled={loadingKeywordsSuggestion} onClick={handleSuggestKeywords}>Suggest keywords (OpenAI / ChatGPT)</TextButton>
                {loadingKeywordsSuggestion && <LinearProgress />}
                {keywordsSuggestion &&  <TextField label="Keywords suggestion" fullWidth multiline disabled value={keywordsSuggestion}/>} 
              </Stack>
            </GridItem4>
            <GridItem4>
              <Stack>
                <TextButton disabled={loadingBestGifSuggestion} onClick={handleSuggestBestGif}>GIF lookup ({bestGifSuggestion?.provider || 'Giphy / Tenor'} + OpenAI / ChatGPT)</TextButton>
                {loadingBestGifSuggestion && <LinearProgress />}
                {bestGifSuggestion && <Stack><TextField label={'Used keywords'} fullWidth multiline disabled value={bestGifSuggestion.keywords}/>
                <Link href={bestGifSuggestion.embedUrl}>{bestGifSuggestion.embedUrl}</Link>
                <GiphyEmbed embedUrl={bestGifSuggestion.embedUrl}/>
                </Stack>}
                </Stack>
            </GridItem4>
          </FormGrid>
          <Stack spacing={1} direction="row-reverse">
            <MainButton variant="contained" onClick={handleSave}>
              {editPost?.post.id ? "Save" : "Create"}
            </MainButton>
            <CancelButton variant="outlined" onClick={handleCancel}>
              Cancel
            </CancelButton>
          </Stack>
        </Stack>
      </Paper>
    </Stack>
  );
};
