export enum Country {
  GB = "GB",
  ES = "ES",
  FR = "FR",
  UA = "UA",
}

export enum CountryLabels {
  gb = "England",
  es = "España",
  fr = "France",
  ua = "Україна",
}