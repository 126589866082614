import { gql } from "graphql-request";
import { queryGraphQL } from "./backend";
import { Language } from "./languages";
import { Country } from "./countries";

export enum Privilege {
  Admin = "ADMIN",
}

export type User = {
  email: string;
  name?: string;
  picture?: string;
  id?: number;
  token?: string;
  privileges?: Array<Privilege>;
};

export const backOfficeLogin = async (
  idToken: string,
  language: Language = Language.EN,
  country: Country = Country.GB
) => {
  const query = gql`
    query BackOfficeLogin($idToken: String!, $language: Language!, $country: Country!) {
      backOfficeLogin(jwtToken: $idToken, language: $language, country: $country) {
        name
        email
        picture
        id
        token
        privileges
      }
    }
  `;
  return await queryGraphQL<User>(
    query,
    { idToken, language, country },
    "backOfficeLogin"
  );
};

export type ActiveUserSession = {
  email: string;
  lastActiveSecs?: number;
};

export const getActiveUserSessions = async (user:User) => {
  const query = gql`
    query ActiveUserSessions($sessionId: Int!, $sessionToken: String!) {
      activeUserSessions(sessionId: $sessionId, sessionToken: $sessionToken)
    }
  `;
  return await queryGraphQL<Array<ActiveUserSession>>(
    query,
    { sessionId:user.id, sessionToken: user.token },
    "activeUserSessions"
  );
};
