import { Box } from "@mui/material";

export const GiphyEmbed = ({
    embedUrl,
  }: {
    embedUrl: string;
  }) => {
    return <Box component={"div"} style={{width:"100%"}}>
        <Box component={"div"} style={{height:0,paddingBottom:"56.25%",position:"relative",width:"100%"}}>
            <iframe title="Gif"  frameBorder={0} height="100%" src={embedUrl} style={{left:0,position:"absolute",top:0,width:"100%"}}/>
        </Box>
     </Box>;
  }

