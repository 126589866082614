import { Alert, AlertTitle, Box, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../store";
import { setLastError } from "../store/session";

export const ErrorAlert = () => {
  const lastError = useAppSelector((state) => state.session.lastError);
  const dispatch = useAppDispatch();
  if (lastError) {
    return (
      <Box paddingBottom={2}>
        <Alert
          severity="error"
          onClose={() => {
            dispatch(setLastError());
          }}
        >
          <AlertTitle>Error</AlertTitle>
          <Typography>{lastError}</Typography>
        </Alert>
      </Box>
    );
  } else {
    return <></>;
  }
};
