import { login, logout, setLastError } from "../store/session";
import { useGoogleOneTapLogin } from "react-google-one-tap-login";
import { useAppSelector, useAppDispatch } from "../store";
import Stack from "@mui/material/Stack";
import Item from "@mui/material/Stack";
import LogoutIcon from "@mui/icons-material/Logout";

import { Avatar, Typography } from "@mui/material";
import { backOfficeLogin } from "../model/users";

export const Login = () => {
  const user = useAppSelector((state) => state.session.user);
  const dispatch = useAppDispatch();

  useGoogleOneTapLogin({
    disabled: user !== undefined,
    onError: (error) => {
      console.error(error);
      dispatch(logout());
    },
    googleAccountConfigs: {
      client_id:
        "452949165347-mmttu4et7lgoo5nocf7o6q8o99v0avnc.apps.googleusercontent.com",
      cancel_on_tap_outside: true,
      callback: ({ credential }) => {
        backOfficeLogin(credential)
          .then(({ data, error }) => {
            console.log();
            if (data) dispatch(login(data));
            if (error) dispatch(setLastError(error));
          })
          .catch((err) => {
            dispatch(setLastError(String(err)));
          });
      },
    },
  });

  if (!user) {
    // Prevent one tap disappear strategy
    document.cookie =
      "g_state=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  }

  return (
    <>
      {user && (
        <Stack direction={"row"} spacing={2} margin={0.5}>
          {user.picture && (
            <Item>
              <Avatar
                alt={user.email}
                src={user.picture}
                imgProps={{ referrerPolicy: "no-referrer" }}
              />
            </Item>
          )}
          <Item>
            <Stack>
              <Item>
                <Typography variant="body2">{user.name || ""}</Typography>
              </Item>
              <Item>
                <Typography variant="caption" style={{ opacity: 0.7 }}>
                  {user.email}
                </Typography>
              </Item>
            </Stack>
          </Item>
          <Item>
            <LogoutIcon onClick={() => dispatch(logout())} />
          </Item>
        </Stack>
      )}
    </>
  );
};
