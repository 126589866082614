import { GraphQLClient } from "graphql-request";

export type ErrorsResponse = { errors: Array<{ message: string }> };

const endpoint =
  window.location.hostname === "localhost"
    ? "http://localhost:8080/graphql"
    : "https://gifview.qwazr.net/graphql";

const client = new GraphQLClient(endpoint);

export interface GraphQLResult<T> {
  data?: T;
  error?: string;
}
export async function queryGraphQL<T>(
  query: string,
  variables: Object,
  resultKey: string
): Promise<GraphQLResult<T>> {
  try {
    const data = await client.request(query, variables);
    return { data: data[resultKey] };
  } catch (err: any) {
    if (err.response) {
      const response = err.response as unknown as ErrorsResponse;
      let message = "";
      for (const error of response.errors) {
        message += error.message + "\n";
      }
      return { error: message };
    }
    if (err.message) {
      return { error: err.messsage };
    }
    return { error: String(err) };
  }
}
