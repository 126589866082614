import { Box, Button, Paper, Stack, Tooltip, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import {
  countInterestRequests,
  deleteInterestRequest,
  getActiveInterestRequests,
  InterestRequest,
  InterestRequestCounts,
} from "../model/requested";
import { useAppDispatch, useAppSelector } from "../store";
import { setLastError } from "../store/session";
import Grid from "@mui/material/Unstable_Grid2";
import { COLORS, SUB_COLORS } from "../theme";
import ReactTimeAgo from "react-time-ago";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import DeleteIcon from "@mui/icons-material/Delete";
import AlertDialog from "../components/AlertDialog";

const PAGE_SIZE = 20;

export const Requested = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.session.user);
  const [from] = useState(0);
  const [requests, setRequests] = useState<InterestRequest[]>([]);
  const [counts, setCounts] = useState<InterestRequestCounts>();
  const [deleteRequestId, setDeleteRequestId] = useState<string>();

  const loadRequests = useCallback(async () => {
    if (user) {
      dispatch(setLastError());

      {
        const { data, error } = await getActiveInterestRequests(
          user,
          from,
          PAGE_SIZE
        );
        if (error) {
          dispatch(setLastError(error));
        }
        setRequests(data || []);
      }

      {
        const { data, error } = await countInterestRequests(user);
        if (error) {
          dispatch(setLastError(error));
        }
        setCounts(data);
      }
    }
  }, [dispatch, from, user]);

  const deleteRequest = useCallback(async () => {
    if (user && deleteRequestId) {
      dispatch(setLastError());
      const { data, error } = await deleteInterestRequest(
        user,
        deleteRequestId
      );
      if (error) {
        dispatch(setLastError(error));
      } else {
        if (data) {
          await loadRequests();
        }
      }
      setDeleteRequestId(undefined);
    }
  }, [deleteRequestId, dispatch, loadRequests, user]);

  useEffect(() => {
    loadRequests().catch((err) => dispatch(setLastError(err)));
  }, [dispatch, loadRequests]);

  return (
    <Stack spacing={2}>
      <Stack direction={"row"} alignItems="center">
        <Typography variant="h4">Requested</Typography>
        {counts && (
          <Stack
            direction={"row"}
            marginLeft={2}
            borderRadius={3}
            color="#ff6162"
            padding={1}
            style={{ backgroundColor: "#f1d1d2" }}
          >
            <Tooltip title="Active">
              <Typography marginRight={1}>{counts.active}</Typography>
            </Tooltip>
            <Tooltip title="Deleted">
              <Typography>({counts.deleted})</Typography>
            </Tooltip>
          </Stack>
        )}
      </Stack>
      {requests.map((request) => (
        <Request
          request={request}
          onPressRemove={() => setDeleteRequestId(request.id)}
        />
      ))}
      {deleteRequestId && (
        <AlertDialog
          close="Cancel"
          ok="Delete"
          title="Interest request deletion"
          content="Please confirm that you want to delete the request."
          onClose={() => setDeleteRequestId(undefined)}
          onOk={() => deleteRequest()}
        />
      )}
    </Stack>
  );
};

interface RequestProps {
  request: InterestRequest;
  onPressRemove: () => void;
}

const Request = ({ request, onPressRemove }: RequestProps) => {
  const date = new Date(0);
  date.setUTCSeconds(request.when);

  return (
    <Paper key={request.id}>
      <Box padding={2}>
        <Stack>
          <Grid container>
            <Grid xs="auto">
              <Stack direction={"row"} spacing={1}>
                <Typography color={COLORS.inactiveNav}>Interest: </Typography>
                <Typography color={SUB_COLORS.PastelRed}>
                  {request.interest}
                </Typography>
              </Stack>
            </Grid>
            <Grid xs>
              <Stack
                direction={"row"}
                justifyContent={"flex-end"}
                spacing={0.4}
              >
                <Typography color={COLORS.inactiveNav}>
                  <AccessTimeFilledIcon />
                </Typography>
                <Typography color={COLORS.inactiveNav}>
                  <ReactTimeAgo date={date} locale="en-US" />
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Stack direction={"row"} spacing={1}>
            <Typography color={COLORS.inactiveNav}>Request by</Typography>
            <Typography>{request.email}</Typography>
          </Stack>
        </Stack>
        <Box paddingTop={2}>
          <Typography color={COLORS.inactiveNav}>Message</Typography>
          <Box
            style={{ backgroundColor: COLORS.mainBackground }}
            padding={1}
            borderRadius={2}
          >
            <Typography>{request.message}</Typography>
          </Box>
        </Box>
        <Box textAlign={"right"} marginTop={2}>
          <Button
            onClick={onPressRemove}
            color="error"
            variant="contained"
            startIcon={<DeleteIcon />}
          >
            Remove Request
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};
