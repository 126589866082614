import {
  Box,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Paper,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { CancelButton, MainButton, TextButton } from "../components/buttons";
import { FormGrid, GridItem1 } from "../components/grids";
import { SelectMenu } from "../components/selectmenu";
import {
  createInterest,
  createSubInterest,
  createSubSubInterest,
  getInterest,
  getInterestUsers,
  getSubInterest,
  getSubInterests,
  getSubSubInterest,
  getSubSubInterests,
  Interest,
  InterestColors,
  updateInterest,
  updateSubInterest,
  updateSubSubInterest,
} from "../model/interests";
import { User } from "../model/users";
import { useAppDispatch, useAppSelector } from "../store";
import { editInterest, InterestType, setLastError, setView, Views } from "../store/session";
import { SUB_COLORS } from "../theme";
import { ChildInterestsPanel } from "./interests";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const PAGE_SIZE = 50;

export const EditInterest = () => {
  const user = useAppSelector((state) => state.session.user);
  const interestId = useAppSelector((state) => state.session.editInterest?.id);
  const interestType = useAppSelector((state) => state.session.editInterest?.type);
  const parentId = useAppSelector((state) => state.session.editInterest?.parentId);
  const dispatch = useAppDispatch();
  const [nameEn, setNameEn] = useState("");
  const [nameEs, setNameEs] = useState("");
  const [nameFr, setNameFr] = useState("");
  const [nameUkr, setNameUkr] = useState("");
  const [active, setActive] = useState(false);
  const [color, setColor] = useState(SUB_COLORS.ViridianGreen);
  const [depth, setDepth] = useState(0);
  const [childInterests, setChildInterests] = useState<Interest[]>([]);
  const [from] = useState(0);
  const [interestUsers, setInterestUsers] = useState<User[]>([]);

  let childInterestType: InterestType | undefined;
  switch (interestType) {
    case 'main':
      childInterestType = 'sub';
      break;
    case 'sub':
      childInterestType = 'subsub';
      break;
  }

  const loadInterest = useCallback(async () => {
    if (user && interestId) {
      dispatch(setLastError());
      
      const set = (interests: Interest[] | undefined) => {
        console.log(`interests ${interests}`);
        if (interests && interests.length > 0) {
          const interest = interests?.slice().pop();
          if (interest) {
            setNameEn(interest.names.en);
            setNameEs(interest.names.es);
            setNameFr(interest.names.fr);
            setNameUkr(interest.names.ukr);
            setDepth(interest.depth);
            setColor(interest.color);
            setActive(interest.active);
          }
        }
      };

      const err = (error: string | undefined) => {
        if (error) {
          dispatch(setLastError(error));
        }
      }

      switch (interestType) {
          case 'main':
            const { data:idata, error:ierror } = await getInterest(user, interestId);
            set(idata);
            err(ierror);
            break;
          case 'sub':
            const { data:sidata, error:sierror } = await getSubInterest(user, interestId);
            set(sidata);
            err(sierror);
            break;
          case 'subsub':
            const { data:ssidata, error:ssierror } = await getSubSubInterest(user, interestId);
            set(ssidata);
            err(ssierror);
            break;
      }
    }
  }, [dispatch, interestId, interestType, user]);

  const loadInterestUsers = useCallback(async () => {
    if (user && interestId) {
      dispatch(setLastError());
      const { data, error } = await getInterestUsers(
        user,
        interestId,
        from,
        PAGE_SIZE
      );
      if (data) {
        setInterestUsers(data);
      }
      if (error) {
        dispatch(setLastError(error));
      }
    }
  }, [dispatch, from, interestId, user]);

  const loadChildInterests = useCallback(async () => {
    if (user && interestId) {
      dispatch(setLastError());

      let interests,error;
      switch (interestType) {
        case 'main':
          const { data:idata, error:ierror } = await getSubInterests(user, interestId, false);
          interests = idata;
          error = ierror;
          break;
        case 'sub':
          const { data:sidata, error:sierror } = await getSubSubInterests(user, interestId, false);
          interests = sidata;
          error = sierror;
          break;
      }
      if (interests) {
        setChildInterests(interests);
      }
      if (error) {
        dispatch(setLastError(error));
      }
    }
  }, [dispatch, interestId, interestType, user]);

  useEffect(() => {
    const load = async () => {
      await loadInterest();
      await loadChildInterests();
      await loadInterestUsers();
    };
    load().catch((error) => dispatch(setLastError(error)));
  }, [dispatch, loadChildInterests, loadInterest, loadInterestUsers]);

  const handleNameChangeEn = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setNameEn(event.currentTarget.value);
    },
    [setNameEn]
  );

  const handleNameChangeEs = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setNameEs(event.currentTarget.value);
    },
    [setNameEs]
  );

  const handleNameChangeFr = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setNameFr(event.currentTarget.value);
    },
    [setNameFr]
  );

  const handleNameChangeUkr = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setNameUkr(event.currentTarget.value);
    },
    [setNameUkr]
  );

  const handleChangeColor = useCallback(
    (color: string) => {
      setColor(color);
    },
    [setColor]
  );

  const handleChangeActive = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setActive(event.target.checked);
    },
    [setActive]
  );

  const handleCreateSubInterest = useCallback(() => {
    switch (depth) {
      case 0:
        dispatch(editInterest({parentId: interestId, type: 'sub'}));
        break;
      case 1:
        dispatch(editInterest({parentId: interestId, type: 'subsub'}));
        break;
    }
  }, [depth, dispatch, interestId]);

  const handleCancel = useCallback(() => {
    dispatch(setView(Views.Interests));
  }, [dispatch]);
  
  const handleSave = useCallback(async () => {
    if (user) {
      let names = {
        en: nameEn,
        es: nameEs,
        fr: nameFr,
        ukr: nameUkr,
      };
      if (interestId) {
        switch (interestType) {
          case 'main':
            await updateInterest(
              user,
              interestId,
              color,
              names,
              active
            );
            break;
          case 'sub':
            if (parentId) {
              await updateSubInterest(
                user,
                interestId,
                parentId,
                names,
                active
              );
            }
            break;
          case 'subsub':
            if (parentId) {
              await updateSubSubInterest(
                user,
                interestId,
                parentId,
                names,
                active
              );
            }
            break;
        }
      } else {
        switch (interestType) {
          case 'main':
            await createInterest(
              user,
              color,
              names,
              active
            );
            break;
          case 'sub':
            if (parentId) {
              await createSubInterest(
                user,
                parentId,
                names,
                active
              );
            }
            break;
          case 'subsub':
            if (parentId) {
              await createSubSubInterest(
                user,
                parentId,
                names,
                active
              );
            }
            break;
        }
      }
      handleCancel();
    }
  }, [user, nameEn, nameEs, nameFr, nameUkr, interestId, handleCancel, interestType, color, active, parentId]);

  let editVerb = interestId ? "Edit" : "Add";
  let childrenTitle;
  switch (depth) {
    case 0:
      childrenTitle = "Sub-Interest";
      break;
    case 1:
      childrenTitle = "Sub-Sub-Interest";
      break;
    default:
      childrenTitle = "";
      break;
  }

  return (
    <Stack spacing={1}>
      <Paper>
        <Stack spacing={1} padding={2}>
          <Stack direction={"row"} justifyContent="space-between">
            <Stack direction={"row"} spacing={1}>
              <Typography variant="h4">{editVerb} /</Typography>
              <Typography variant="h4">{nameEn}</Typography>
            </Stack>
            {interestId && childrenTitle && (
              <TextButton onClick={handleCreateSubInterest}>
                Click here to create a {childrenTitle}
              </TextButton>
            )}
          </Stack>
          <br />
          <FormGrid>
            <GridItem1>
              <TextField
                fullWidth
                id="name-en"
                label="English"
                value={nameEn}
                onChange={handleNameChangeEn}
              />
            </GridItem1>
            <GridItem1>
              <TextField
                fullWidth
                id="name-es"
                label="Spanish"
                value={nameEs}
                onChange={handleNameChangeEs}
              />
            </GridItem1>
            <GridItem1>
              <TextField
                fullWidth
                id="name-fr"
                label="French"
                value={nameFr}
                onChange={handleNameChangeFr}
              />
            </GridItem1>
            <GridItem1>
              <TextField
                fullWidth
                id="name-ukr"
                label="Ukrainian"
                value={nameUkr}
                onChange={handleNameChangeUkr}
              />
            </GridItem1>
          </FormGrid>
          <FormGrid>
            {interestType === 'main' && (
              <GridItem1>
                <Stack direction={"row"} spacing={1}>
                  <SelectMenu
                    fullWidth
                    id="color"
                    value={color}
                    label="Color"
                    onChange={handleChangeColor}
                  >
                    {Object.keys(SUB_COLORS).map((color) => (
                      <MenuItem
                        style={{
                          backgroundColor: SUB_COLORS[color as InterestColors],
                        }}
                        key={color}
                        value={SUB_COLORS[color as InterestColors]}
                      >
                        {color}
                      </MenuItem>
                    ))}
                  </SelectMenu>
                  <Box
                    alignSelf={"center"}
                    bgcolor={color}
                    width={50}
                    height={40}
                  />
                </Stack>
              </GridItem1>
            )}
            <GridItem1>
              <FormGroup>
                <FormControlLabel
                  labelPlacement="top"
                  control={
                    <Switch checked={active} onChange={handleChangeActive} />
                  }
                  label="Active"
                />
              </FormGroup>
            </GridItem1>
          </FormGrid>
          <Stack spacing={1} direction="row-reverse">
            <MainButton variant="contained" onClick={handleSave}>
              {interestId ? "Save" : "Create"}
            </MainButton>
            <CancelButton variant="outlined" onClick={handleCancel}>
              Cancel
            </CancelButton>
          </Stack>
        </Stack>
      </Paper>
      {childInterestType && childInterests && childInterests.length > 0 && (
        <Paper>
          <ChildInterestsPanel
            title={`${childrenTitle}s`}
            interests={childInterests}
            type={childInterestType}
            language={"en"}
          />
        </Paper>
      )}
      <InterestUsers interestUsers={interestUsers} />
    </Stack>
  );
};

const InterestUsers = ({ interestUsers }: { interestUsers: User[] }) => {
  return (
    <Paper>
      <Typography paddingTop={2} paddingLeft={2} variant="h5" paddingRight={2}>
        Users
      </Typography>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography fontWeight="bold">Username</Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight="bold">Email</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {interestUsers.map((interestUser) => (
              <TableRow
                key={interestUser.email}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {interestUser.name}
                </TableCell>
                <TableCell>{interestUser.email}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
