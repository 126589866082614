import { gql } from "graphql-request";
import { queryGraphQL } from "./backend";
import { User } from "./users";

export interface InterestRequest {
  id: string;
  when: number;
  email: string;
  interest: string;
  message: string;
}

export const getActiveInterestRequests = async (
  user: User,
  from: number,
  size: number
) => {
  const query = gql`
    query GetActiveInterestRequests(
      $sessionId: Int!
      $sessionToken: String!
      $from: Int!
      $size: Int!
    ) {
      getActiveInterestRequests(
        sessionId: $sessionId
        sessionToken: $sessionToken
        from: $from
        size: $size
      ) {
        id
        when
        email
        interest
        message
      }
    }
  `;
  return await queryGraphQL<Array<InterestRequest>>(
    query,
    { sessionId: user.id, sessionToken: user.token, from, size },
    "getActiveInterestRequests"
  );
};

export interface InterestRequestCounts {
  active: number;
  deleted: number;
}

export const countInterestRequests = async (user: User) => {
  const query = gql`
    query CountInterestRequests($sessionId: Int!, $sessionToken: String!) {
      countInterestRequests(sessionId: $sessionId, sessionToken: $sessionToken) {
        active
        deleted
      }
    }
  `;
  return await queryGraphQL<InterestRequestCounts>(
    query,
    { sessionId: user.id, sessionToken: user.token },
    "countInterestRequests"
  );
};

export const deleteInterestRequest = async (
  user: User,
  requestId: string
) => {
  const query = gql`
    mutation DeleteInterestRequest($sessionId: Int!, $sessionToken: String!, $requestId: String!) {
      deleteInterestRequest(sessionId: $sessionId, sessionToken: $sessionToken, requestId: $requestId)
    }
  `;
  return await queryGraphQL<boolean>(
    query,
    { sessionId: user.id, sessionToken: user.token, requestId },
    "deleteInterestRequest"
  );
};
