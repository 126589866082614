import {
    Paper,
    Stack,
    LinearProgress,
    TextField,
    Typography,
    Link,
    Table,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
    Button,
    TableFooter,
  } from "@mui/material";
  import { FormGrid, GridItem2, GridItem4 } from "../components/grids";
  import { InterestsMenu } from "../components/InterestsMenu";
  import { useCallback, useEffect, useState } from "react";
  import { useAppDispatch, useAppSelector } from "../store";
  import { setLastError, setView, Views } from "../store/session";
  import { suggestInterests } from "../model/interests";
  import { MainButton, TextButton } from "../components/buttons";
import { getPostInterests,  insertPostInterest,  PostInterest, removePostInterest } from "../model/posts";
import { Language } from "../model/languages";
import { TableLabel } from "../components/tables";
import DeleteIcon from "@mui/icons-material/Delete";
import AlertDialog from "../components/AlertDialog";

export const EditPostInterests = () => {
    const user = useAppSelector((state) => state.session.user);
    const editPost = useAppSelector((state) => state.session.editPost);
    const dispatch = useAppDispatch();
    const [loadingInterestsSuggestion, setLoadingInterestsSuggestion] = useState(false);

    const [interestsSuggestion, setInterestsSuggestion] = useState<string>();
    const [interests, setInterests] = useState<PostInterest[]>();
    const [interestId, setInterestId] = useState<number>();
    const [subInterestId, setSubInterestId] = useState<number>();
    const [subSubInterestId, setSubSubInterestId] = useState<number>();
    const [removePostInterestId, setRemovePostInterestId] = useState<number>();

    const loadPostInterests = useCallback(async () => {
        if (user && editPost?.post.id) {
          dispatch(setLastError());
          {
            const { data, error } = await getPostInterests(user, Language.EN, editPost?.post.id);
            if (error) {
              dispatch(setLastError(error));
            }
            setInterests(data || []);
          }
        }
      }, [dispatch, editPost?.post.id, user]);
    
      useEffect(() => {
        loadPostInterests().catch((err) => dispatch(setLastError(err)));
      }, [dispatch, loadPostInterests]);

    const handleClose = useCallback(() => {
        if (editPost?.back === 'live') {
          dispatch(setView(Views.LivePosts));
        } else {
          dispatch(setView(Views.PendingPosts));
        }
      }, [dispatch, editPost?.back]);

    const handleSuggestInterests = useCallback(async () => {
        if (user && editPost?.post.title && editPost?.post.description) {
          setInterestsSuggestion(undefined);
          dispatch(setLastError());
          try {
            setLoadingInterestsSuggestion(true);
            const {data} = await suggestInterests(user, editPost.post.title, editPost.post.description);
            setInterestsSuggestion(data?.response);
          } catch (err) {
            dispatch(setLastError(String(err)));
          } finally {
            setLoadingInterestsSuggestion(false);
          }
        }
        }, [dispatch, user, editPost]);

        const onInsertPostInterest = useCallback(async () => {
            if (user && editPost?.post.id && interestId && subInterestId && subSubInterestId) {
              dispatch(setLastError());
              const { data, error } = await insertPostInterest(
                user,
                editPost?.post.id, 
                interestId,
                subInterestId,
                subSubInterestId
              );
              if (error) {
                dispatch(setLastError(error));
              } else {
                if (data) {
                  await loadPostInterests();
                }
              }
            }
          }, [user, editPost?.post.id, interestId, subInterestId, subSubInterestId, dispatch, loadPostInterests]);

        const onRemovePostInterest = useCallback(async () => {
            if (user && removePostInterestId) {
              dispatch(setLastError());
              const { data, error } = await removePostInterest(
                user,
                removePostInterestId
              );
              if (error) {
                dispatch(setLastError(error));
              } else {
                if (data) {
                  await loadPostInterests();
                  setRemovePostInterestId(undefined);
                }
              }
            }
          }, [dispatch, loadPostInterests, removePostInterestId, setRemovePostInterestId, user]);

    return (
        <Stack spacing={1}>
      <Paper>
        <Stack spacing={1} padding={2}>
          <Stack direction={"row"} justifyContent="space-between">
            <Stack direction={"row"} spacing={1}>
              <Typography variant="h4">Edit Post Interests</Typography>
            </Stack>
          </Stack>
          <br />
            <FormGrid>
            <GridItem4>
            <Stack spacing={2}>
              <TextField
                fullWidth
                id="title"
                label="Title"
                type={"text"}
                value={editPost?.post.title}
                disabled
              />
               <TextField
                fullWidth
                id="description"
                label="Description"
                type={"text"}
                value={editPost?.post.description}
                disabled
              />
                <Typography variant="body2">
                  <Link href={editPost?.post.source} target={"_blank"}>{editPost?.post.source}</Link>
                </Typography>
                <TextField
                fullWidth
                id="tags"
                label="Tags"
                multiline
                type={"text"}
                value={editPost?.post.tags}
                disabled
              />
              </Stack>
            </GridItem4>
            </FormGrid>
            <FormGrid>
            <GridItem2>
            
            </GridItem2>
            <GridItem2>
             
            </GridItem2>
            <GridItem2>
             
            </GridItem2>
          </FormGrid>
          <FormGrid>
            <GridItem4>
              <Stack>
                <TextButton disabled={loadingInterestsSuggestion} onClick={handleSuggestInterests}>Suggest interests (OpenAI / ChatGPT)</TextButton>
                {loadingInterestsSuggestion && <LinearProgress />}
                {interestsSuggestion &&  <TextField label="Interests suggestion" fullWidth multiline disabled value={interestsSuggestion}/>}
              </Stack>
            </GridItem4>
            </FormGrid>
            <Stack spacing={1} direction="row-reverse">
            <MainButton variant="contained" onClick={handleClose}>
              Close
            </MainButton>
          </Stack>
          <Table size="small">
          <TableHead>
            <TableRow>
              <TableLabel label="Main" />
              <TableLabel label="Sub" />
              <TableLabel label="Sub-Sub" />
              <TableLabel label="Actions"/>
            </TableRow>
          </TableHead>
          <TableBody>
            {interests?.map((p) => (
              <TableRow
                key={p.subSubInterest.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>{p.interest.name}</TableCell>
                <TableCell>{p.subInterest.name}</TableCell>
                <TableCell>{p.subSubInterest.name}</TableCell>
                <TableCell>
                    <Button
                        onClick={() => setRemovePostInterestId(p.id)}
                        color="error"
                        variant="contained"
                        startIcon={<DeleteIcon />}
                        >
                        Remove
                    </Button>
                </TableCell>
            </TableRow>
            ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell>
                                <InterestsMenu
                                    interestType="main"
                                    label=""
                                    selectedInterestId={interestId}
                                    fullWidth={true}
                                    onChange={setInterestId}
                                 />
                            </TableCell>
                            <TableCell>
                                <InterestsMenu
                                    parentId={interestId}
                                    interestType="sub"
                                    label=""
                                    selectedInterestId={subInterestId}
                                    fullWidth={true}
                                    onChange={setSubInterestId}
                                />
                            </TableCell>
                            <TableCell>
                                <InterestsMenu
                                    parentId={subInterestId}
                                    interestType="subsub"
                                    label=""
                                    selectedInterestId={subSubInterestId}
                                    fullWidth={true}
                                    onChange={setSubSubInterestId}
                                />
                            </TableCell>
                            <TableCell>
                            <Button
                                onClick={() => onInsertPostInterest()}
                                color="primary"
                                disabled={!(editPost?.post.id && interestId && subInterestId && subSubInterestId)}
                                variant="contained">
                                Add
                            </Button>
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                    </Table>
            </Stack>
        </Paper>
        {removePostInterestId && (
        <AlertDialog
          close="Cancel"
          ok="Remove"
          title="Remove Interest"
          content='Click on "Remove"  to confirm'
          onClose={() => setRemovePostInterestId(undefined)}
          onOk={() => onRemovePostInterest()}
        />
      )}
        </Stack>
    )
}