import { Box, LinearProgress, Paper, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { ActiveUserSession, getActiveUserSessions } from "../model/users";
import { useAppDispatch, useAppSelector } from "../store";
import { setLastError } from "../store/session";

export const ActiveUsers = () => {
  const user = useAppSelector((state) => state.session.user);
  const dispatch = useAppDispatch();
  const [activeUserSessions, setActiveUserSessions] = useState<
    Array<ActiveUserSession> | undefined
  >();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const request = async () => {
      if (user) {
        dispatch(setLastError());
        setIsLoading(true);
        const { data, error } = await getActiveUserSessions(user);
        if (data) setActiveUserSessions(data);
        if (error) dispatch(setLastError(error));
      }
    };
    request()
      .catch((err) => dispatch(setLastError(String(err))))
      .finally(() => setIsLoading(false));
  }, [user, dispatch]);

  if (isLoading) {
    return (
      <Box sx={{ width: "100%" }}>
        <LinearProgress />
      </Box>
    );
  }

  if (!activeUserSessions) {
    return <></>;
  }
  return (
    <Stack spacing={2}>
      {activeUserSessions.map((activeUserSession) => {
        const lastActive = activeUserSession.lastActiveSecs
          ? new Date(activeUserSession.lastActiveSecs * 1000).toLocaleString()
          : "";
        return (
          <Paper
            elevation={1}
            style={{ padding: 4 }}
            key={activeUserSession.email}
          >
            <Typography variant={"body2"}>{activeUserSession.email}</Typography>
            <Typography variant={"caption"} style={{ opacity: 0.7 }}>
              {lastActive}
            </Typography>
          </Paper>
        );
      })}
    </Stack>
  );
};
