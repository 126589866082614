import { useAppSelector } from "../store";
import { Views } from "../store/session";
import { ActiveUsers } from "./activeusers";
import { Connectors } from "./connectors";
import { Dashboard } from "./dashboard";
import { EditConnector } from "./editconnector";
import { EditInterest } from "./editinterest";
import { Interests } from "./interests";
import { LivePosts } from "./liveposts";
import { PendingPosts } from "./pendingposts";
import { Requested } from "./requested";
import { EditPost } from "./editpost";
import { EditPostInterests } from "./editpostinterests";
import { DigitalDetox } from "./digitaldetox";
import { EditActivity } from "./editactivity";

export const View = () => {
  const view = useAppSelector((state) => state.session.view);
  switch (view) {
    default:
    case Views.Dashboard:
      return <Dashboard />;
    case Views.Interests:
      return <Interests />;
    case Views.EditInterest:
      return <EditInterest />;
    case Views.InterestRequests:
      return <Requested />;
    case Views.Connectors:
      return <Connectors />;
    case Views.EditConnector:
      return <EditConnector />;
    case Views.PendingPosts:
      return <PendingPosts />;
    case Views.EditPost:
      return <EditPost />;
    case Views.EditPostInterests:
      return <EditPostInterests/>;
    case Views.LivePosts:
      return <LivePosts />;
    case Views.DigitalDetox:
      return <DigitalDetox />;
    case Views.EditActivity:
      return <EditActivity />;
    case Views.ActiveUsers:
      return <ActiveUsers />;
  }
};
